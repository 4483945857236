.price-card-container {
  margin: .1rem;
  display: flex;
  width: 400px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--txt-border, #263238);
  background: #fff;
  transition: color 0.3s ease;
  &:hover {
    &.style-1 {
      border: 6px dashed #0593f5;
      background: rgba(5, 147, 245, 0.5);
      backdrop-filter: blur(5px);
      padding: 17px;
    }
    &.style-2 {
      border: 6px dashed #ffcd48;
      background: rgba(255, 219, 128, 0.5);
      backdrop-filter: blur(5px);
      padding: 17px;
    }
    &.style-3 {
      border: 6px dashed #47e683;
      background: rgba(128, 255, 175, 0.5);
      backdrop-filter: blur(5px);
      padding: 17px;
    }
  }
  @media (max-width: 1024px) {
    // display: flex;
    width: 700px;
    border: 2px solid var(--txt-border, #263238);
    // width: 310px;
    margin: .5rem;
    
  }
  @media (max-width: 430px) {
    width: 90%;
    margin-bottom: 20px;
  }
}
.custom-next-arrow-price,
.custom-next-arrow-price {
  top:40%;
  @media (max-width: 430px) {
    top:40%
  }
  
}

.custom-next-arrow-price{
  background-color: red;
  @media (max-width: 798px) {
    // right:-27px;

  }
}

.price-card-container:hover .price-card {
  border: 1px solid var(--txt-border, #263238);
  background: #d7f9e3;
}
.price-card {
  display: flex;
  width: 90%;
  // height: 750px;
  max-height: 100%;
  min-height: calc(100% - 60px);
  padding: 30px 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border: 1px solid var(--txt-border, #263238);
  background: #fff;
  @media (max-width: 430px) {
    width: 100%;
  }
  @media (max-width: 430px) {
    width: 100%;
  }
  &:hover {
    &.style-1 {
      border: 1px solid var(--txt-border, #263238);
      background: #d7f9e3;
    }
    &.style-2 {
      border: 1px solid var(--txt-border, #263238);
      background: #cde9fd;
    }
    &.style-3 {
      border: 1px solid var(--txt-border, #263238);
      background: #fff1cc;
    }
  }

  @media (max-width: 768px) {
    gap: 12px;
    width: 267px;
    padding: 20px 10px;
  }
  @media (max-width: 430px) {
    width: 90%;
    padding: 20px 10px;
  }
}
.price-card-title {
  margin: 0;
  align-self: stretch;
  color: var(--txt-normal, #222);
  text-align: center;
  font-family: Delius;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  @media (max-width: 1030px) {
    font-size: 34px;
    line-height: 31.2px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 31.2px;
  }
  @media (max-width: 430px) {
    font-size: 16px;
    line-height: 31.2px;
  }
}

.price-card-dop {
  font-size: 25px;
  font-weight: 400;
  text-align: center;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 400;
  }
  @media (max-width: 430px) {
    font-size: 14px;
  }
}

.price-card-text {
  margin: 0;
  align-self: stretch;
  color: var(--txt-normal, #222);
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 400;
    line-height: 24.2px;
  }
  @media (max-width: 430px) {
    font-size: 16px;
    line-height: 18.2px;
  }
  
}

.price-card-description {
  line-height: 24.2px;
  font-size: 16px;

  @media (max-width: 1024px) {
    font-size: 40px;
    line-height: 120%;
  }
  @media (max-width: 768px) {
    font-size: 17px;
  }

  @media (max-width: 430px) {
    font-size: 16px;
  }
}

.price-card-img {
  width: 100%;
  height: 250px;
  border-radius: 14px;


  @media (max-width: 1024px) {
    width: 100%;
    height: 500px;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 250px;
  }
  @media (max-width: 430px) {
    width: 100%;
    height: 250px;
  }
}

.price-card-name,
.price-card-price {
  width: 100%;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 24px;
    font-weight: 600;
  }

}

.price-card-name{
  @media (max-width: 1024px) {
    font-size: 40px;
    width: 100%;
  }
  @media (max-width: 430px) {
    font-size: 20px;
  }
  
}

.price-card-name_small {
  width: 80%;
}

.price-card-button {
  font-size: 22px;
  font-weight: 300;
  margin-top: 5px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
  @media (max-width: 430px) {
    font-size: 16px;
    width: 90%;
  }
}

.price-card-button:hover {
  background: #80ffaf;
  color: black;
}

.price-card-name-desc{
font-weight: 100;
}
