.text-block-lims {
  display: flex;
    position: relative;
width: 582px;
padding: 20px;
height: 140px;
flex-direction: column;
justify-content: center;
align-items: center;
border: 2.5px solid var(--txt-border, #263238);
background: #FFF;
backdrop-filter: blur(12.5px);
z-index: 1;


@media (max-width: 1024px){
    width: 800px;
    margin-top: 50px;
   
}
  @media (max-width: 768px)  {
    padding: 0px;
    border: 2.5px;
    border: 2.5px solid #263238;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    width: 339px;
    height: 91px;
    padding: 20px;
    gap: 20px;
    margin-top: 80px;
    margin-left: 24px;
  }
  @media (max-width: 430px) {
    width: 315px;
    height: 100px;
    padding: 15px;
    gap: 20px;
    border: 1px 0px 0px 0px;
    border: 1px solid #263238;
    margin: 0px auto;
}
}
.text-block-lims p {
    color: var(--txt-normal, #222);
font-style: normal;
margin: 0;
padding: 0;
font-family: Gilroy;
font-size: 30px;
font-weight: 400;
line-height: 40px;
letter-spacing: 0em;
text-align: left;
@media (max-width: 1024px){
    font-size: 35px;
    line-height: 45px;
}
  @media (max-width: 768px)  {
    font-size: 20px;
    line-height: 21.85px;
  }
  @media (max-width: 430px)  {
    font-size: 20px;
    line-height: 21px;
  }
}
.text-block-lims span {
    color: #0593F5;
font-size: 35px;
font-weight: 600;
line-height: 40px;
letter-spacing: 0em;
text-align: left;
  @media (max-width: 768px)  {
    font-size: 22px;
    line-height: 21.85px;
  }
  @media (max-width: 430px)  {
    font-size: 20px;
    line-height: 21.6px;
  }
}
.text-block-company-with-header {
    @media (max-width: 1024px){
        width: 800px
    }
    display: flex;
width: 585px;
min-height: 370px;
max-height: 100%;
padding: 20px;
flex-direction: column;
justify-content: center;
align-items: flex-start; 
border: 2.5px solid var(--txt-border, #263238);
background: #FFF;
backdrop-filter: blur(12.5px);
}
.text-block-company-with-header h2 {
    color: #0D3754;
    font-family: Gilroy-Medium;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
margin: 0;
}
.text-block-company-with-header p, .text-block-company-with-header ul {
    color: var(--txt-normal, #222);
    font-family: Gilroy-Medium;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; 
margin: 0;
padding: 0;
}
.text-block-company-with-header p {
    margin-top: 20px;
    @media  (max-width : 1024px) {
        font-size:35px
      }
  
}

.text-block-company-with-header ul {

    margin-left: 35px;
}
.text-block-company-with-header li {
    margin: 0;
    margin-top: 10px;
    @media   (max-width : 1024px) {
        margin-top: 5px;
        font-size:40px;
        line-height: 150%; 
      }
      @media   (max-width : 768px) {
        margin-top: 5px;
        font-size:25px;
        line-height: 150%; 
      }
      @media   (max-width : 430px) {
        margin-top: 5px;
        font-size:15px;
        line-height: 130%; 
      }
}
.text-block-program-with-header {
    display: flex;
padding: 30px;
width: 804px;
height: auto;
flex-direction: column;
align-items: start;
border: 2.5px solid var(--txt-border, #263238);
background: #FFF;
// backdrop-filter: blur(12.5px);
@media (max-width: 1440px)  {
    width: 704px;
}

@media (max-width: 1024px)  {
    width: 820px;
}

}
.text-block-program-with-header h2 {
    color: #0D3754;
    font-family: Gilroy-Medium;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; 
    width: 804px;
    margin: 0;
padding: 0;
}
.text-block-program-with-header-text,
.text-block-program-with-header-after-list,
.text-block-program-with-header-list-title,
.text-block-program-with-header ul {
color: var(--txt-normal, #222);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 130%; 
margin: 0;
padding: 0;
}

.text-block-program-with-header-text {
    margin-top: 30px;
    font-size: 25px;
    @media (max-width: 1024px)  {
        width: 100% ;
        font-size: 35px;
    }
}
.text-block-program-with-header-list-title {
    margin-top: 40px;
    font-size: 25px;
    @media (max-width: 1024px)  {
        font-size: 40px;
    }

}
.text-block-program-with-header ul {
    margin-top: 20px;
    margin-left: 30px;
    font-size: 22px;
    display: flex;
    flex-flow: column;
    gap: 10px 0px;
    @media (max-width: 1024px)  {
        font-size: 40px;
        gap: 15px;
    }
}
.text-block-program-with-header-after-list {
    margin-top: 30px;
    font-size: 25px;
    @media (max-width: 1024px)  {
        font-size: 35px;
    }
}
.trusted-companies {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}
.trusted-companies .company {
    display: flex;
    flex-direction: row;
}
.trusted-companies p {
font-size: 26px;
font-weight: 400;
line-height: 34px;
letter-spacing: 0em;
text-align: left;
margin: auto 20px;
}
.trusted-companies img {
    width: 46px;
    height: 46px;
}
.small-text-block {
    width: 825px;
height: 50px;
padding: 10px 20px 10px 20px;
border: 2px;
background: #FFFFFF;
border: 2px solid #263238;
margin-top: 20px;
display: flex;
flex-direction: row;
@media (max-width: 1440px)  {
    width: 724px;
    height: 70px;
}
}
.small-text-block-p {
font-size: 24px;
font-weight: 500;
line-height: 31px;
height: 31px;
letter-spacing: 0em;
text-align: left;
margin: auto;
width: 680px;
@media (max-width: 1440px)  {
    height: 70px;
}
}
.small-text-block-download {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    cursor: pointer;
    p {
    color:  #0593F5;
    font-family: Gilroy-Medium;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; 
    margin-bottom: 19.5px;
    border-bottom: 1px solid #0593F5;
    margin-right: 5px;
    }
    svg {
    width: 50px;
    height: 50px;
    margin-top: 5px;
    cursor: pointer;
    }
    }
.text-block-introduction-first {
    display: flex;
width: 800px;
max-height: 100%;
padding: 20px;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
border: 2.5px solid var(--txt-border, #263238);
background: #FFF;
backdrop-filter: blur(12.5px);
z-index: 5;
font-size: 18px;
}
.text-block-introduction-first ul {
    margin: 10px 0;
    padding: 0;
    margin-left: 20px;
    @media (max-width: 1024px)  {
        font-size:35px;
        display: flex;
        flex-flow: column;
        gap: 10px 0;
    }
}
.text-block-introduction-first-title {
    color: var(--txt-normal, #222);
    font-family: Gilroy;
    font-size:18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    margin: 0;
    padding: 0;
    @media (max-width: 1024px)  {
        font-size:40px;
    }

}
.text-block-introduction-first-text {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    line-height: 130%;
    @media (max-width: 1024px)  {
        font-size:35px;
    }
}
.text-block-introduction-first-listTitle {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    @media (max-width: 1024px)  {
        font-size:35px;
        line-height: 120%;
    }
}
.text-block-introduction-first-afterListText {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    @media (max-width: 1024px)  {
        font-size:35px;
        line-height: 120%;
    }
}

.text-block-introduction-second {
    display: flex;
width: 800px;
height: 100%;

padding: 20px;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
border: 2.5px solid var(--txt-border, #263238);
background: #FFF;
backdrop-filter: blur(12.5px);
z-index: 5;
font-size: 18px;
line-height: 130%;
@media (max-width: 1024px)  {
    backdrop-filter: none;
}
}
.text-block-introduction-second h2{
margin: 0;
padding: 0;
color: var(--txt-normal, #222);
font-family: Gilroy;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 130%;
@media (max-width: 1024px) {
    font-size: 40px;
}
}
.text-block-introduction-second ul{
    margin: 0;
    padding: 0;
    margin-left: 20px;
    margin-top: 10px;
    

}
.text-block-introduction-second p{
    margin: 0;
    padding: 0;
    margin-top: 10px;
    line-height: 130%;
    @media (max-width: 1024px) {
        font-size: 35px;
        line-height: 130%;
    }
}
.text-block-introduction-second-list-item{
    @media (max-width: 1024px) {
        font-size: 35px;
        line-height: 130%;
  
    }
}



@media (max-width: 768px) {
    .text-block {
        display: flex;
        width: 350px;
        height: 184px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0px;
        border: 2px solid var(--txt-border, #263238);
background: #FFF;
backdrop-filter: blur(12.5px);
    }
    .text-block ul {
        color: var(--txt-normal, #222);
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
        
    }
    .text-block li {
width: 280px;

    }
    .text-block-company-with-header {
justify-content: flex-start;
gap: 16px;
width: 339px;
height: 428px;
padding: 20px;
gap: 16px;
height: auto;
margin-left: 24px;
    }
    .text-block-company-with-header h2 {
        font-size: 24px;
        font-weight: 400;
        line-height: 31.2px;
    }
    .text-block-company-with-header p, .text-block-company-with-header ul {
        font-family: Gilroy;
        font-size: 16px;
        font-weight: 500;
        line-height: 20.8px;
    }
    .text-block-company-with-header p {
        margin-top: 0px;
    }
    .text-block-company-with-header ul {
        margin-left: 20px;
    }
    .text-block-company-with-header li {
        margin: 0;
        margin-top: 15px;
    }
    .text-block-program-with-header {
        display: flex;
        width: 660px;
        height: auto;
        padding: 30px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-shrink: 0;
        border: 2px solid var(--txt-border, #263238);
background: #FFF;
backdrop-filter: blur(12.5px);
    }
    .text-block-program-with-header h2 {
        color: #0D3754;
        font-family: Gilroy-Medium;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        width: 660px;
    }
    .text-block-program-with-header-text,
    .text-block-program-with-header-after-list,
    .text-block-program-with-header-list-title,
    .text-block-program-with-header ul {
        color: var(--txt-normal, #222);
        font-family: Gilroy;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
    }
    .text-block-program-with-header-text {
        margin-top: 24px;
        
    }
    .text-block-program-with-header-list-title {
        margin-top:30px;
        
   
    }
    .text-block-program-with-header ul {
        margin-left: 20px;
    }
    .text-block-program-with-header-after-list {
        margin-top: 20px;
    }
.trusted-companies p {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    margin: auto 20px;
    }
    .trusted-companies img {
        width: 40px;
        height: 40px;
    }
    .small-text-block {
        width: 680px;
    height: 30px;
    padding: 10px 20px 10px 20px;
    border: 2px;
    background: #FFFFFF;
    border: 2px solid #263238;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    }
    .small-text-block-p {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        height: 18px;
    }
    .text-block-introduction-first {
        display: flex;
    width: 660px;
    height: 392px;
    padding: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 2.5px solid var(--txt-border, #263238);
    background: #FFF;
    backdrop-filter: blur(12.5px);
    }
    .text-block-introduction-first ul {
        margin: 0;
        padding: 0;
        margin-left: 20px;
    }
    .text-block-introduction-first-title {
        color: var(--txt-normal, #222);
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        margin: 0;
        padding: 0;
    }
    .text-block-introduction-first-text {
        margin: 0;
        padding: 0;
        margin-top: 30px;
        line-height: 25px;
    }
    .text-block-introduction-first-listTitle {
        margin: 0;
        padding: 0;
        margin-top: 30px;
    }
    .text-block-introduction-first-afterListText {
        margin: 0;
        padding: 0;
        margin-top: 30px;
    }
    .text-block-introduction-second {
        display: flex;
    width: 660px;
    height: 392px;
    padding: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 2.5px solid var(--txt-border, #263238);
    background: #FFF;
    backdrop-filter: blur(12.5px);
    }
    .text-block-introduction-second h2{
    margin: 0;
    padding: 0;
    color: var(--txt-normal, #222);
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    }
    .text-block-introduction-second ul{
        margin: 0;
        padding: 0;
        margin-left: 20px;
        margin-top: 10px;
    }
    .text-block-introduction-second p{
        margin: 0;
        padding: 0;
        margin-top: 20px;
        line-height: 20px;
    }
}
@media (max-width: 430px) {
    .text-block {
        display: flex;
    width: 335px;
    height: 160px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #000;
background: #FFF;
backdrop-filter: blur(12.5px);
margin: 0 auto;
margin-top: 12px;
position: sticky;
z-index: 5;
    }
    .trusted-companies p {
        font-size: 16px;
        font-weight: 400;
        line-height: 15.6px;
        letter-spacing: 0em;
        text-align: left;
        margin: auto 10px;
        }
        .trusted-companies img {
            width: 26px;
            height: 26px;
        }
.small-text-block {
    width: 300px;
height: 25px;
padding: 10px 20px 10px 20px;
border: 2px;
background: #FFFFFF;
border: 2px solid #263238;
margin-top: 10px;
display: flex;
flex-direction: row;
}
.small-text-block-p {
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
}
.small-text-block-download {
    align-items: center;
    height: 24px;
}
.small-text-block-download p {
    font-size: 16px;
    font-weight: 500;
    line-height: 15.6px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
}
.small-text-block-download svg {
    width: 24px;
    height: 24px;
}
.trusted-companies {
    margin-right: auto;
}
    .text-block ul {
        color: #000;
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        padding-left: 30px;
    }
    .text-block-company-with-header {
        display: flex;
        width: 319px;
        height: auto;
        padding: 8px;
        border: 1px solid #000;
background: #FFF;
backdrop-filter: blur(12.5px);
margin: 0 auto;
justify-content: flex-start
    }
    .text-block-company-with-header h2 {
        color: #0D3754;
        font-family: Gilroy-Medium;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%; 
        margin: 0;
        padding: 0;
    }
    .text-block-company-with-header p, .text-block-company-with-header ul {
        color: #000;
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; 
        margin: 0;
        padding: 0;
    }
    .text-block-company-with-header p {
        margin-top: -10px;
    }
    .text-block-company-with-header ul {
        margin-left: 20px;
    }
    .text-block-company-with-header li {
        margin: 0;
        margin-top: 0px;
        margin-bottom: 15px;
    }
    .text-block-program-with-header {
        display: flex;
        width: 316px;
        height: auto;
        padding: 12px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        border: 2px solid var(--txt-border, #263238);
background: #FFF;
backdrop-filter: blur(12.5px);
    }
    .text-block-program-with-header h2 {
        color: #0D3754;
        font-family: Gilroy-Medium;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        width: 316px;
    }
    .text-block-program-with-header-text,
    .text-block-program-with-header-after-list,
    .text-block-program-with-header-list-title,
    .text-block-program-with-header ul {
        font-size: 16px;
        line-height: 20.2px;
    }
    .text-block-program-with-header-text {
        margin-top: 14px;
    }
    .text-block-program-with-header-list-title {
        margin-top: 20px;
    }
    .text-block-program-with-header-after-list {
        margin-top: 20px;
        margin-right: auto;
    }
    .text-block-introduction-first {
        display: flex;
    width: 660px;
    height: 392px;
    padding: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 2.5px solid var(--txt-border, #263238);
    background: #FFF;
    backdrop-filter: blur(12.5px);
    }
    .text-block-introduction-first ul {
        margin: 0;
        padding: 0;
        margin-left: 20px;
    }
    .text-block-introduction-first-title {
        color: var(--txt-normal, #222);
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        margin: 0;
        padding: 0;
    }
    .text-block-introduction-first-text {
        margin: 0;
        padding: 0;
        margin-top: 30px;
        line-height: 25px;
    }
    .text-block-introduction-first-listTitle {
        margin: 0;
        padding: 0;
        margin-top: 30px;
    }
    .text-block-introduction-first-afterListText {
        margin: 0;
        padding: 0;
        margin-top: 30px;
    }
    .text-block-introduction-second {
        display: flex;
    width: 660px;
    height: 392px;
    padding: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border: 2.5px solid var(--txt-border, #263238);
    background: #FFF;
    backdrop-filter: blur(12.5px);
    }
    .text-block-introduction-second h2{
    margin: 0;
    padding: 0;
    color: var(--txt-normal, #222);
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    }
    .text-block-introduction-second ul{
        margin: 0;
        padding: 0;
        margin-left: 20px;
        margin-top: 10px;
        
        
    }
    .text-block-introduction-second p{
        margin: 0;
        padding: 0;
        margin-top: 20px;
        line-height: 20px;
   
    }
}
.company-logos-slider{
    margin-top: 20px;
   
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center
}
.company-logos-slider-container{
width: 90%;

}
.company-logos-slider-item{
    margin-left: 15px;
}
.company-logos-slider-item p {
width: 100px;
height: 100%;
word-break: break-all;
text-align: center;
@media (max-width: 1024px){
font-size: 30px;
width: 100%;
}
@media (max-width: 430px){
    font-size: 12px;

    }
}

.company-logos-slider-item img{
    width: 46px;
    height: 46px;
    object-fit: contain;
    margin-left: 20%;
    @media (max-width: 1024px){
        width: 106px;
        height: 106px;
        margin-left: 30%;
        }
        @media (max-width: 430px){
            width: 46px;
            height: 46px;
            object-fit: contain;
            margin-left: 20%;
    }
}

.custom-next-arrow{
    background:none;
}