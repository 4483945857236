.price-info {
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 30px;

  @media (max-width: 430px){
    margin-top: 0;
  }
}

@keyframes movePriceAtom1 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(50px, 50px);
  }
  100% {
    transform: translate(100px, 100px);
  }
}
@keyframes movePriceAtom2 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(150px, -150px);
  }
  100% {
    transform: translate(200px, 0);
  }
}
.price-atom-1 {
  position: absolute;
  top: 30px;
  right: 410px;
  z-index: -1;
  pointer-events: none;
  animation: movePriceAtom1 10s infinite alternate;
  @media (max-width: 768px) {
    width: 200px;
  }
}
.price-atom-2 {
  position: absolute;
  bottom: 130px;
  left: 350px;
  z-index: -1;
  pointer-events: none;
  animation: movePriceAtom2 10s infinite alternate;
  @media (max-width: 768px) {
    width: 200px;
  }
}

/* mobile */
.price-info-section-container {
  h2 {
    margin-left: 5%;
  }
}
.price-cards-container {
  display: flex;
//   flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  gap: 60px;

  // @media (max-width: 768px) {
  //   gap: 20px;
  // }
}
.mobile-price-cards-sub-container {
  display: flex;
  align-items: flex-start;
  gap: 100px;
}
.price-cards-container .slick-slider {
//   width: 339px !important;
width: 80%;
margin: auto;

@media (max-width: 768px) {
  width: 90%;
}
}

.slider-container {
    width: max-content;
    display: flex !important;
    justify-content: center;
    gap: 60px;
    @media (max-width: 768px) {
      gap: 20px;
    }
}

.dots-container{
  @media (max-width: 430px){
    display: none;
  }
}

.price-cards-container .slick-prev,
.price-cards-container .slick-next {
  font-size: 16px;
}

.custom-prev-arrow,
.custom-next-arrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  
  transform: translateY(-50%);
  z-index: 1;
  top: 40%;
  @media (max-width: 430px){
    top: -30%;
    
  }
}


.custom-next-arrow {
  @media (max-width: 768px){
    left: -35px;
  }
  @media (max-width: 430px){
    right: -15%;
  }
}

.custom-prev-arrow {
  left: 98%;
  @media (max-width: 768px){
    left: 100%;
  }
  @media (max-width: 430px){
    left: 88%;
  }
}

.custom-next-arrow svg,
.custom-prev-arrow svg {
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(3.6500000953674316px);
}

.dots-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    @media (max-width: 430px){
      margin-top: 0;
    }
}

.dot {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background-color: #D9D9D9;
    margin: 0 2px;
}

.dot.active {
    background-color: #0593F5; 
}

@keyframes moveMobilePriceAtom1 {
  0% {
    transform: translate(0px, 00px) rotate(90deg);
  }
  25% {
    transform: translate(-25px, 00px) rotate(180deg);
  }
  50% {
    transform: translate(-50px, 00px) rotate(270deg);
  }
  75% {
    transform: translate(-75px, 000px) rotate(360deg);
  }
  100% {
    transform: translate(-100px, 00px) rotate(450deg);
  }
}

.mobile-price-atom-1 {
  position: absolute;
  bottom: -170px;
  right: 0px;
  z-index: -1;
  animation: moveMobilePriceAtom1 20s infinite alternate;
}
