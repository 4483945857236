.introduction-section {
    margin-top: 100px;
    width: 100%;
max-width: 1900px;
  }
  .introduction-text-blocks-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    font-size: 40px;
  }
  /* tablet */
  .tablet-introduction-section {
width: 100%;
height: 100%;
margin-top: 50px;
h2 {
  margin-left: 24px;
}
  }
.text-block-introduction-second__text p{
  @media (max-width: 1024px) {
    font-size: 35px;
    line-height: 150%;
    font-weight: 200;
    
}
}
.tablet-introduction-text-blocks-container {
  display: flex;
  width: 90%;
  height: 100%;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border: 2px solid var(--txt-border, #263238);
background: #FFF;
backdrop-filter: blur(12.5px);
margin: 0 auto;
}


.tablet-text-block {

    .tablet-introduction-second-text-block-list{
    margin-top: 20px;
    display: flex;
    flex-flow: column;
    gap: 10px;
    }



p, li, ul {
  margin: 0;
padding: 0;
  color: var(--txt-normal, #222);
font-family: Gilroy;
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: 130%;
}
li {
  margin-left: 20px;
}
h2 {
  color: var(--txt-normal, #222);
font-family: Gilroy;
font-size: 30px;
font-style: normal;
line-height: 150%; /* 18.2px */
margin: 0;
padding: 0;
margin-top: 20px;
}
p {
margin-top: 30px;
}
}
.introduction-button {
  margin-left: auto;
  margin-top: 20px;
  @media (max-width: 768px)  {
margin-right: 24px;
}
  @media (max-width: 430px)  {
    width: 236px;
    height: 43px;
    padding: 22px, 16px 22px 16px;
    border: 3px;
    gap: 6px;
    border: 3px solid #0593F5    ;
    margin-right: 20px;
}
}
  /* mobile */
  .mobile-introduction-section {
    margin: 0 20px;
    width: 100%;
    margin-top: 50px;
    h2 {
      margin-left: 20px;
    }
      }
    .mobile-introduction-text-blocks-container {
      display: flex;
      width: auto;
      max-width: 420px;
      margin: 0 20px;
      height: auto;
      padding: 12px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
      border: 2px solid var(--txt-border, #263238);
      background: #FFF;
      backdrop-filter: blur(12.5px);
    }


    .mobile-text-block {
    p, li, ul {
      margin: 0;
    padding: 0;
    color: var(--txt-normal, #222);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    }
    ul {
      margin-bottom: 20px;
    }
    li {
      margin-left: 20px;
    }
    h2 {
      color: var(--txt-normal, #222);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    line-height: 130%;
    margin: 0;
    padding: 0;
    }
    p {
    margin-top: 20px;
    }
    .mobile-introduction-second-text-block-list{
      margin-top: 20px;
      display: flex;
      flex-flow: column;
      gap: 10px;
      }
    
    }

    
  @keyframes moveMobileAtom1 {
    0% {
      transform: translate(0px, 3800px) rotate(90deg);
    }
    25% {
      transform: translate(-25px, 3950px) rotate(180deg);
    }
    50% {
      transform: translate(-50px, 4100px) rotate(270deg);
    }
    75% {
      transform: translate(-75px, 4000px) rotate(360deg);
    }
    100% {
      transform: translate(-100px, 3900px) rotate(450deg);
    }
  }
  @keyframes moveMobileAtom2 {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(20px, 0px);
    }
    100% {
      transform: translate(40px, 0px);
    }
  }
  @keyframes moveTabletAtom {
    0% {
      transform: translate(0px, 800px) rotate(90deg);
    }
    25% {
      transform: translate(75px, 900px) rotate(180deg);
    }
    50% {
      transform: translate(150px, 1100px) rotate(270deg);
    }
    75% {
      transform: translate(225px, 1000px) rotate(360deg);
    }
    100% {
      transform: translate(300px, 900px) rotate(450deg);
    }
  }
  
  .mobile-plan-scheme-atom-1 {
    position: absolute;
    top: -20px;
    right: 15px;
    z-index: -1;
    pointer-events: none;
    animation: moveMobileAtom1 20s infinite alternate;
  }
  .mobile-plan-scheme-atom-2 {
    position: absolute;
    bottom: -3230px;
    left: 0px;
    z-index: -1;
    pointer-events: none;
    animation: moveMobileAtom2 10s infinite alternate;
  }
  .tablet-plan-scheme-atom {
    position: absolute;
    left: -20px;
    z-index: -1;
    width: 200px;
    pointer-events: none;
    animation: moveTabletAtom 10s infinite alternate;
  }
  @keyframes movePlanSchemeAtom {
    0% {
      transform: translate(-200px, -400px) rotate(90deg);
    }
    25% {
      transform: translate(-500px, -100px) rotate(180deg);
    }
    50% {
      transform: translate(-800px, 200px) rotate(270deg);
    }
    75% {
      transform: translate(-500px, 500px) rotate(360deg);
    }
    100% {
      transform: translate(-200px, 800px) rotate(450deg);
    }
  }
  
  .plan-scheme-atom {
    position: absolute;
    right: 0px;
    z-index: -1;
    overflow: hidden;
    pointer-events: none;
    animation: movePlanSchemeAtom 30s infinite alternate;
  }
  