.react-tel-input {
  display: flex;
  justify-content: flex-start ;
  outline: none ;
  font-family: Gilroy;
  font-size: 20px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  display: flex !important;
  width: 550px !important;
  height: 14px !important;
  padding: 18px 16px ;
  align-items: center ;
  border: 1px solid var(--txt-border, #263238) !important;
  border-radius: 0px;
  @media (max-width: 768px) {
    width: 448px !important;
    height: 11px !important;
    padding: 22px 16px 22px 16px !important;
  }
  @media (max-width: 430px) {
    width: 262px !important;
    height: 4px !important;
    padding: 18px 16px !important;
  }
}
.phone-input-container {
  text-align: right;
}
.react-tel-input .form-control {
  outline: none !important;
  border: none !important;
font-size: 20px;
font-weight: 400;
line-height: 26px;
padding: 18px 45px;
align-items: center;
border: 1px solid var(--txt-border, #263238);
border-radius: 0px;
@media (max-width: 768px) {
font-size: 14px !important;
font-weight: 400 !important;
line-height: 18.2px !important;
}
@media (max-width: 430px) {
  display: flex;
  width: 263px;
  height: 4px;
  padding: 18px 45px;
  justify-content: center;
  align-items: center;
  gap: 16px;
font-size: 16px;
border: 1px solid var(--txt-border, #263238);
background: #FFF;
}
}
.react-tel-input .flag-dropdown {
  border: none !important;
  background-color: inherit !important;
}
.text-input-container {
text-align: end;
}
.text-input-field {
    display: flex;
justify-content: center;
align-items: center;
outline: none;
font-family: Gilroy;
font-size: 20px;
font-weight: 400;
line-height: 26px;
margin-top: -5px;
margin-bottom: 5px;
display: flex;
width: 548px;
height: 14px;
padding: 18px 16px;
align-items: center;
border: 1px solid var(--txt-border, #263238);
border-radius: 0px;
@media (max-width: 768px) {
  width: 448px;
  height: 11px;
  padding: 22px 16px 22px 16px;
font-size: 14px;
font-weight: 400;
line-height: 18.2px;
border-radius: 0px;
}
@media (max-width: 430px) {
  display: flex;
  width: 263px;
  height: 4px;
  padding: 17px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
font-size: 16px;
border: 1px solid var(--txt-border, #263238);
background: #FFF;
}
}
.text-input-field::placeholder, .react-tel-input .form-control::placeholder {
    color: var(--grey, #949599);
}
.text-without-star {
  margin-top: 25px;
}
.text-asterisk {
    align-items: flex-end;
    color: var(--red, #620000);
    text-align: right;
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    @media (max-width: 430px) {
font-size: 16px;
line-height: 130%; 
    }
}
.text-label {
  align-items: flex-end;
  color: var(--red, #620000);
  text-align: right;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
        @media (max-width: 430px) {
    font-size: 16px;
    line-height: 130%; 
    line-height: 25px;
        }
}
.select-input-container, .selected-option, .select-dropdown-options, .select-input-wrapper, .select-active-input {
    display: flex;
width: 580px;
flex-direction: column;
border-radius: 0px;
@media (max-width: 768px) {
  width: 482px;
}
@media (max-width: 430px) {
  width: 300px;
}
}
.select-label {
    display: flex;
height: 27px;
flex-direction: column;
justify-content: center;
align-self: stretch;color: var(--txt-normal, #222);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 26px; 
width: 578px;
@media (max-width: 430px) {
  width: 300px;
  font-size: 16px !important;
}
}
.select-input-wrapper {
    display: flex;
justify-content: center;
align-items: center;
outline: none;
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 130%;
}
.selected-option {
    display: flex;
    border: none;
    width: 548px;
    height: 10px;
    padding: 18px 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
background: #FFF;
color: #FFF;
@media (max-width: 430px) {
  width: 268px;
  height: 4px;
  padding: 17px 16px;
}
}
  .select-label {
    font-family: Gilroy;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    color: var(--txt-normal, #222);
    width: 580px;
    margin-bottom: 6px;
    @media (max-width: 430px) {
      font-size: 16px;
    }
  }
  .selected-option {
    flex: 1;
    padding: 18px 20px;
    background: #FFF;
    color: var(--grey, #949599);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 26px;
align-items: flex-start;
@media (max-width: 768px) {
  width: 482px;
  height: 11px;
  padding: 22px 16px 22px 16px;
font-size: 14px;
font-weight: 400;
line-height: 18.2px;
}
@media (max-width: 430px) {
  font-size: 16px;
  padding: 17px 16px;
}
  }
  .select-dropdown-options {
    position: absolute;
    top: 100%;
   width: 584px;  
   max-height: 210px; 
   overflow-y: auto; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background: #FFF;
    z-index: 1;
    display: flex;
flex-direction: column;
gap: 10px;
flex: 1 0 0;
color: var(--txt-normal, #222);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 130%;
border: 1px solid #0593F5;
border-top: none;
padding-bottom: 20px;
border-radius: 0px;
@media (max-width: 768px) {
  width: 482px;
font-size: 14px;
font-weight: 400;
line-height: 18.2px;
}
@media (max-width: 430px) {
  width: 300px;
  font-size: 16px !important;
}
  }
  .select-dropdown-option {
    padding: 20px 0px;
    margin-right: 20px;
    margin-left: 20px;
    cursor: pointer;
    border-bottom: 0.5px solid #949599
  }
.select-input {
    position: relative;
    display: flex;
    align-items: center;
    width: 584px; 
    border: 1px solid #263238;
    border-radius: 0px;
    @media (max-width: 768px) {
      width: 482px;
    }
    @media (max-width: 430px) {
      width: 300px;
      font-size: 16px !important;
    }
  }
  .select-active-input {
    .select-input {
border: 1px solid #0593F5;
border-radius: 0px;
border-bottom: none;
    }
    @media (max-width: 430px) {
      width: 300px;
      margin-left: 0px;
      font-size: 16px !important;
    }
  }
  .select-dropdown-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
    @media (max-width: 430px) {
    width: 24px;
    height: 24px;
    }
  }
  .number-input-container {
    display: flex;
width: 580px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 6px;
@media (max-width: 768px) {
  width: 482px;
}
@media (max-width: 430px) {
  width: 300px;
}
  }
  .number-label {
    color: var(--txt-normal, #222);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 130%;
@media (max-width: 430px) {
  font-size: 16px;
  margin-top: 10px;
}
  }
  .number-input {
    display: flex;
    width: 548px;
    height: 14px;
    padding: 18px 16px;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    border: 1px solid var(--accent-2, #0593F5);
    border-radius: 0px;
background: #FFF;
@media (max-width: 768px) {
  width: 448px;
  height: 11px;
  padding: 22px 16px 22px 16px;
font-size: 14px;
font-weight: 400;
line-height: 18.2px;
}
@media (max-width: 430px) {
width: 268px;
padding: 17px 16px;
height: 4px;
}
  }
  .number-input-field {
    border: none;
    display: flex;
height: 27px;
flex-direction: column;
justify-content: center;
flex: 1 0 0;
color: #000;
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 26px;
outline: none;
@media (max-width: 430px) {
  font-size: 16px;
}
  }
  .number-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  .number-button {
    display: flex;
width: 16px;
height: 16px;
padding: 3px;
justify-content: center;
align-items: center;
border: 0.5px solid #000;
border-radius: 0px;
  }
  .comment-input-container {
    margin-top: 10px;
  }
  .comment-input-container input{
    display: flex;
    width: 549px;
    height:14px;
    padding: 18px 16px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border: 1px solid var(--txt-border, #263238);
    border-radius: 0px;
    background: #FFF;
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 130%;
margin-top: 5px;
outline: none;
@media (max-width: 768px) {
  width: 448px;
  height: 11px;
  padding: 22px 16px 22px 16px;
font-size: 14px;
font-weight: 400;
line-height: 18.2px;
}
@media (max-width: 430px) {
  display: flex;
  width: 263px;
  height: 4px;
  padding: 17px 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
font-size: 16px;
border: 1px solid var(--txt-border, #263238);
border-radius: 0px;
background: #FFF;
}
  }
  .comment-label {
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 130%; 
@media (max-width: 768px) {
  font-size: 16px;
}
@media (max-width: 430px) {
  font-size: 16px;
}
  }
  .comment-input-container::placeholder {
    color: var(--grey, #949599);
}