.feedback-button-container {
  position: fixed;
  bottom: 5%;
  right: 3%;
  z-index: 9;
}
.feedback-main-button, .feedback-buttons button {
  padding: 0;
  margin: 0;
  background-color: inherit;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  user-select: auto;
  svg {
    @media (max-width: 430px) {
      width: 46px;
      height: 46px;
    }
  }
}
.feedback-buttons {
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none; 
}
.feedback-button-container.open .feedback-buttons {
  opacity: 1;
  pointer-events: auto; 
}
.feedback-button-container.closed .feedback-buttons {
  opacity: 0;
  pointer-events: none; 
}
.scroll-to-top-button {
    position: fixed;
    bottom: 5%;
    left: 3%;
    opacity: 0;
    transition: opacity 0.5s ease; 
    z-index: 9;
  }
  .scroll-to-top-button button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  .scroll-to-top-button .button-icon {
    width: 50px;
    height: 50px;
    @media (max-width: 430px) {
      width: 32px;
      height: 32px;
    }
  }
.scroll-to-top-button.show {
    opacity: 1;
  }
  .scroll-to-top-button.hide {
    opacity: 0; 
  }
  .button-style-1 {
    cursor: pointer;
display: flex;
width: 280px;
height: 60px;
padding: 22px 8px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border: 3px solid var(--txt-border, #263238);
background: var(--accent-2, #0593F5);
color: var(--white, #FFF);
text-align: center;
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 130%;
&:hover {
border: 3px solid var(--txt-border, #263238);
background: var(--accent-2, #0593F5);      }
&:active {
    background: var(--accent-2, #0593F5);
}

&:disabled {
    border: 1px solid var(--grey, #949599);
background: #FFF;
color: var(--grey, #949599);
font-weight: 500;
line-height: 130%;
flex: 1 0 0;
}
@media (max-width: 768px) {
    display: flex;
    width: 236px;
    height: 58px;
    padding: 22px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
font-size: 16px;
font-weight: 500;
line-height: 130%;
}
}
.button-style-2 {
    cursor: pointer;
display: flex;
width: 280px;
height: 58px;
padding: 22px 16px;
justify-content: center;
align-items: center;
gap: 6px;
flex-shrink: 0;
border: 3px solid var(--accent-2, #0593F5);
background: #FFF;
height: 26px;
flex: 1 0 0;
color: var(--accent-2, #0593F5);
text-align: center;
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 130%;
&:hover {
background: var(--accent-2, #0593F5);   
flex: 1 0 0;
color: var(--white, #FFF);
text-align: center;
font-style: normal;
font-weight: 600;
line-height: 130%;   
}
&:active {
    background: var(--accent-2, #0593F5);
color: var(--white, #FFF);
text-align: center;
font-weight: 600;
    }
    &:disabled {
    border: 1px solid var(--grey, #949599);
    background: var(--white, #FFF);
    height: 26px;
flex: 1 0 0;
color: var(--grey, #949599);
text-align: center;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 130%;
    }
    @media (max-width: 768px) {
    display: flex;
    width: 236px;
    height: 14px;
    padding: 22px 10px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    font-size: 16px;
    white-space: nowrap;
    }
}
.button-style-3 {
@extend .button-common-styles !optional;
display: flex;
width: 370px;
padding: 22px 8px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border: 3px solid var(--txt-border, #263238);
background: var(--accent-2, #0593F5);
box-shadow: 0px 1px 2px 0px rgba(172, 71, 15, 0.10), 0px 4px 4px 0px rgba(172, 71, 15, 0.09), 0px 10px 6px 0px rgba(172, 71, 15, 0.05), 0px 17px 7px 0px rgba(172, 71, 15, 0.01), 0px 26px 7px 0px rgba(172, 71, 15, 0.00);
color: var(--white, #FFF);
text-align: center;
font-family: Gilroy;
font-size: 24px;
font-style: normal;
font-weight: 800;
line-height: 130%;
&:hover {
background: var(--accent-2, #0593F5);   
text-align: center;
font-style: normal;
}  
&:active {
    background: var(--accent-2, #0593F5);
}
&:disabled {
    border: 2px solid var(--grey, #949599);
    color: var(--grey, #949599);
text-align: center;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: normal;;
}
    @media (max-width: 768px) {
    display: flex;
    width: 319px;
    height: 16px;
    padding: 22px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border: 3px solid var(--txt-border, #263238);
background: var(--accent-2, #0593F5);
color: var(--white, #FFF);
text-align: center;
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 130%; 
    }
    @media (max-width: 430px) {
      width: 319px;
      height: 26px;
      padding: 27px 8px 27px 8px;
      gap: 10px;
      border: 3px solid #263238;
      font-size: 16px;
      font-weight: 600;
      line-height: 20.8px;
      text-align: center;
      }
}
.button-style-4 {
    cursor: pointer;
@extend .button-common-styles !optional;
display: inline-flex;
padding: 9px 20px;
justify-content: center;
align-items: center;
gap: 10px;
border: 2px solid var(--accent-2, #0593F5);
background: #FFF;
color: var(--accent-2, #0593F5);
text-align: center;
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 130%; 
&:hover {
background: var(--accent-2, #0593F5);   
color: white;
text-align: center;
font-family: Gilroy;
font-style: normal;
font-weight: 500;
line-height: 130%; 
}  
@media (max-width: 768px) {
display: flex;
width: 132px;
padding: 9px 20px;
justify-content: center;
align-items: center;
gap: 10px;
}
}
.custom-button:hover svg, .custom-button:active svg {
    fill: var(--white, #FFF);
stroke-width: 1px;
stroke: var(--white, #FFF);
}
.custom-button:disabled svg {
    stroke-width: 1px;
    stroke: var(--grey, #949599);
}
.custom-button {
    transition: all 0.3s;
    cursor: pointer;
  }
  .button-style-1 {
    position: relative;
    overflow: hidden;
  }
  .color-button {
    position: relative;
    z-index: 1;
    overflow: hidden; 
    width: 260px;
    @media (max-width: 768px) {
      height: 58px;
      padding: 22px 8px 22px 8px;
      gap: 10px;
      border: 2px 0px 0px 0px;
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      border: 2px solid var(--txt-border, #263238);
    }
  }
  .header-button{
    max-width: 100%;
    max-height: 100%;
    @media (max-width: 1024px) {
    font-size: 30px;
    width: 100%;
    height: 100%;
    }

  }
  .color-button::before {
    content: "";
    position: absolute;
    top: -3px; 
    left: -300%;
    width: 200%;
    height: calc(100% + 6px); 
    background: linear-gradient(135deg, transparent 0%, #80FFAF 50%, transparent 100%);
    animation: shine 5s infinite linear;
    z-index: 2;  mask-image: linear-gradient(to right, transparent 0%, black 10%, black 90%, transparent 100%);
  }
  @keyframes shine {
    to {
      left: 100%;
    }
  }
  