.header-overlay {
width: 100%;
}
.button-text{
  @media (max-width: 1024px) {
    font-size: 40px;
  }
}
header {
  position: fixed;
  top: 0;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
border-bottom: 0.1px solid var(--txt-border, #E0E3EB);
background: rgba(255, 255, 255, 0.60);
box-shadow: 0px 2px 2px 0px rgba(3, 2, 15, 0.04);
backdrop-filter: blur(12px);
z-index: 9;
@media (max-width: 768px) {
  border: none;
  zoom: 1;
  height: 174px;
  border-bottom: 0.5px solid var(--txt-border, #263238);
}
  }
.header-container {
  position: relative;
  display: flex;
  flex-direction: row;
height: 157px;
margin: 0 auto;
@media (max-width: 768px) {
width: 100%;
}
@media (max-width : 1024px) {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
}
.header-text-2 {
font-size: 30px;
font-weight: 400;
line-height: 36.4px;
text-align: left;
display: flex;
align-items: center;
gap: 10px;
@media (max-width : 1034px) {
  img{
    width: 80px;
  }
}
@media (max-width : 1024px) {
  font-size:55px;
}

}
.header-center {
  width: 100%;
  @media (max-width : 1054px) {
    font-size:45px;
  }
}
  .header-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #E0E3EB;
    width: 100%;
    
  }
  .mobile-header-logo, .header-logo  {
    cursor: pointer;
  }
  .header-block-2 {
    display: inline-flex;
    align-items: center;
    gap: 0 20px;
    @media (max-width : 1024px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }


  .header-button {
    margin-top: 79px;
    margin-left: 11px;
  }
  .header-container-svg {
    border: 1px dashed var(--grey, #949599);
width: 36px;
height: 36px;
padding: 2px;
justify-content: center;
align-items: center;
cursor: pointer;
@media (max-width : 1024px) {
width: 70px;
height: 70px;
}
img{
  @media (max-width : 1024px) {
    width: 70px;
    }
}
  }
  .header-svg-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px; 
    right: -80px;
    pointer-events: none;
    @media (max-width: 1280px) {
display: none;
    }
  }
  .header-logo {
    width: 237px;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 26px;
    margin-right: 42px;
    @media (max-width: 768px) {
      width: 180px;
      height: 76px;
      margin: 0;
          }
  }
  .header-bottom {
    margin: 25px 50px;

  }
  .header-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    list-style: none;
    padding: 0;
    @media (max-width : 1024px) {
    gap: 0 20px;
    }
  }
  .header-list li {
    color: #000;
    font-family: Gilroy;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    cursor: pointer;
    text-decoration: none;
    transition: color 0.3s ease, text-decoration 0.3s ease;
    @media (max-width : 1024px) {
      font-size: 40px;
    }
    @media (max-width : 820px) {
      font-size: 50px;
    }
  }
  
  .header-list li:hover {
    color: #0D3754;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-decoration: underline; 
  }
  
  /* tablet */
  .tablet-header {
    width: 100%;
margin: 0 auto;
  }
  .tablet-header-top {
    display: flex;
    flex-direction: row;
    margin: 0 12px;
    margin-top: 24px;
    gap: 30px;
    justify-content: space-between;
    .tablet-header-logo-block {
      display: flex;
      flex-direction: row;
      gap: 30px;
    }
    .tablet-header-blocks {
  display: flex;
  flex-direction: column;
}
.tablet-header-block-1, .tablet-header-block-2 {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  
}
.tablet-header-block-1 {
  margin-bottom: 0px;
  gap: 10px;
  .tablet-header-text-2 {
    display: flex;
    align-items: center;
    gap: 0 5px;
    color: #0D3754;
    font-family: Gilroy;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    margin: 7px auto;
  } 
  .tablet-header-icon{
    width: 40px;
    cursor: pointer;
  }
}
.tablet-header-block-2 {
  gap: 10px;
  display: inline-flex;
align-items: center;
  .tablet-header-text-3 {
    width: 119px !important;
    height: 21px;
  }
}
.tablet-header-container-svg {
  border: 1px dashed var(--grey, #949599);
  width: 30px;
height: 30px;
display: flex;
align-items: center;
gap: 10px;
margin: 0;
}
  }
  .tablet-header-line {
    width: 654px;
height: 0.5px;
background: #E0E3EB;
margin: 0 auto;
margin-top: 10.8px;
  }
  .tablet-header-list {
    display: flex;
align-items: center;
justify-content: center;
gap: 12px;
list-style: none;
color: #000;
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 130%;
margin-top: 24px;
padding: 0;
  }
.tablet-header-list li {
  cursor: pointer;
}
.tablet-header-list li:hover {
  text-decoration-line: underline;
}
  /* mobile */
  @media (max-width: 430px) {
    header {
      height: auto;
    }
    .header-container {
      margin: 0px 20px;
      width: 100%;
      height: auto;
    }
   }
  .mobile-header {
display: flex;
flex-direction: row;
width: 100%;
height: 69px;
flex-shrink: 0;
justify-content: space-between;
margin: 0;
.mobile-header-logo {
  width: 120px;
height: 59px;
flex-shrink: 0;
}
  }
  .mobile-header-blocks {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
  }
  .mobile-header-logo-block {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
  .mobile-header-block-1, .mobile-header-block-2 {
    display: flex;
    flex-direction: row;
  }
  .mobile-header-block-1 {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #000;
font-family: Gilroy;
font-size: 19px;
font-style: normal;
font-weight: 500;
line-height: 130%; 
    .mobile-header-text-2 {
      color: #0D3754;
      font-family: Gilroy;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; 
      display: flex;
      align-items: center;
      gap: 5px;
    }
    .mobile-header-icon{
      cursor: pointer;
      width: 20px;
    }
  }
  .mobile-header-block-2 {
    display: flex;
align-items: center;
gap: 10px;
    color: #000;
    font-family: Gilroy;
    font-size: 19px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; 
    .mobile-header-text-3 {
      width: 90px;
      height: 10px;
    }
  }
  .mobile-header-container-svg {
    margin: 0;
    display: flex;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    border: 1px dashed var(--grey, #949599);
    img {
      width: 18px;
height: 18px;
flex-shrink: 0;
    }
  }
  .mobile-menu {
    width: 26px;
height: 26px;
border: none;
background-color: inherit;
margin-top: 20px;
margin-right: 40px;
padding: 0;
z-index: 100;
  }
  .mobile-menu-list {
    display: inline-flex;
flex-direction: column;
align-items: center;
gap: 30px;
list-style: none;
color: #000;
text-align: center;
font-family: Gilroy;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: 130%;
  }
  .mobile-menu-modal {
    position: fixed;
    top: 69px;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: white; 
    z-index: 999; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    @media (max-width: 320px) {
      top: 49px;
    }
  }
  .mobile-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10; 
  }
  .mobile-menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: -100px;
  }
  
  .mobile-menu-list li {
    font-size: 28px;
    margin-bottom: 5px; 
    cursor: pointer;
  }
  
  .mobile-menu-button {
    margin-top: 20px;
  }

  .menu-icon {
    width: 26px;
    height: 20px;
    z-index: 100;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .menu-icon .bar {
    width: 100%;
    height: 2px;
    background-color: #000;
    display: block;
    transition: transform 0.3s ease;
  }
  .menu-open .menu-icon .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-6px, 6px);
    background-color: #949599;
  }
  .menu-open .menu-icon .bar:nth-child(2) {
    opacity: 0;
  }
  .menu-open .menu-icon .bar:nth-child(3) {
    transform: rotate(45deg) translate(-6px, -6px);
    background-color: #949599;
  }
  .header-container.menu-open {
    background-color: white;
    margin: 0;
    padding: 0 20px;
  }