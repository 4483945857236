.card-with-icons-container {
    display: flex;
    width: 472px;
    height: 347px;
    padding: 0px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border: 2.5px solid var(--txt-border, #263238);
    background: var(--white, #FFF);
    @media (max-width: 1024px){
      height: 600px;
      align-items: start;
      justify-content: start;
    }
    @media (max-width: 768px) {
      align-items: start;
      justify-content: start;
      flex-shrink: 0;
      border: 1px solid var(--txt-border, #263238);
background: var(--white, #FFF);
width: 318px;
height: 300px;
padding: 10px 16px 10px 16px;
border: 2px 0px 0px 0px;
    }
    @media (max-width: 430px) {
      width: 300px;
      min-height: 230px;
      height:300px;
      padding: 0px 16px 0px 16px;
      gap: 0px;
      margin-top: 10px;
    }
    .card-with-icons-content-container {
      display: flex;
      padding: 10px 10px 20px 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      @media (max-width: 430px) {
gap: 0px;
      }
      .card-with-icons-icon-container svg {
        width: 70px;
        height: 70px;
        @media (max-width: 1024px){
         margin-top: 30px;
        }
        @media (max-width: 768px) {
          width: 60px;
          height: 60px;
        }
        @media (max-width: 430px) {
          width: 50px;
          height: 50px;
        }
      }
      .card-with-icons-text-container {
        max-width: 452px;
        align-self: stretch;
        color: var(--txt-normal, #222);
        text-align: center;
        font-family: Gilroy;
        font-size: 20px;
        letter-spacing: 0.8px;
        line-height: 130%;
        @media (max-width:1024px) {
          font-size: 30px;
        }
        @media (max-width: 768px) {
          font-size: 14px;
          text-align: center;
            margin-top: 0px;
        }
        @media (max-width: 430px) {
          font-size: 14px;
        }
      }
    }
  }
  