.gift-modal-text {
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: #222222;

  ul {
    margin-top: 40px;
  }

  h2 {
    font-weight: 500 !important;
  }
}

.gift-modal {
  height: 80vh !important;
}

.gift-modal-content {
  padding-top: 20px !important;
}

.error-overlay {
  position: fixed;
  top: 15%;
  z-index: 9999;
  right: 0;
  ;
  animation: slideInFromRight 0.5s ease-in-out forwards, fadeIn 0.5s ease-in-out forwards;

  @media (max-width: 430px) {
    top: 5%;
  }
}

.error-message {
  width: 765px;
  height: 31px;
  padding: 24px;
  border-radius: 10px 0px 0px 10px;
  background: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
  color: #222222;

  @media (max-width: 768px) {
    width: 422px;
    padding: 16px 24px;
    height: 34px;
    border-radius: 10px 0px 0px 10px;
    font-size: 24px;
    font-weight: 500;
    line-height: 31.2px;
  }

  @media (max-width: 430px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    text-align: left;
    width: 314px;
    height: 48px;
    top: 114px;
    left: 61px;
    padding: 12px 16px 12px 16px;
    gap: 10px;
    border-radius: 10px 0px 0px 10px;
  }
}

.error-icon {
  margin-right: 10px;

  @media (max-width: 768px) {
    width: 34px;
    height: 34px;
    margin: 0;
  }

  @media (max-width: 430px) {
    width: 24px;
    height: 24px;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.success-container {
  position: fixed;
  z-index: 11;
  width: 580px;
  height: 127px;
  padding: 40px;
  border: 2.5px solid #263238;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 430px) {
    width: 274px;
    height: 132px;
    padding: 20px;
    gap: 0;
  }
}

.success-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;

  @media (max-width: 430px) {
    gap: 0px;
    flex-direction: column;
  }
}

.success-message {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: #222222;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 31.2px;
  }

  @media (max-width: 430px) {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.success-button {
  @media (max-width: 768px) {
    width: 280px;
    height: 60px;
    padding: 22px 8px 22px 8px;
    gap: 10px;
    border: 3px 0px 0px 0px;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
  }

  @media (max-width: 430px) {
    width: 236px;
    height: 58px;
    padding: 22px 8px 22px 8px;
    gap: 10px;
    border: 2px 0px 0px 0px;
    border: 2px solid #263238;
    font-size: 24px;
    line-height: 31.2px;
  }
}

.send-request-modal {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 2.5px solid var(--txt-border, #263238);
  background: var(--white, #FFF);

  @media (max-width: 768px) {
    zoom: 0.95;
  }

  .send-request-modal-content {
    background: #fff;
    width: 580px;
    padding: 10px 40px 10px 40px;

    @media (max-width: 768px) {
      max-width: 480px;
    }

    @media (max-width: 430px) {
      display: flex;
      width: 276px;
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 0px;
      padding: 0px 40px 40px 40px;
    }

    p {
      color: var(--txt-normal, #222);
      font-family: Gilroy;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      margin: 20px 0;
      padding: 0;

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 20.8px;
      }

      @media (max-width: 430px) {
        font-size: 14px;
        text-align: center;
        margin: 10px;
      }
    }
  }

  .send-request-modal-header h2 {
    color: var(--txt-normal, #222);
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 0px;

    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 31.2px;
    }

    @media (max-width: 430px) {
      font-size: 18px;
    }
  }

  .send-request-modal-close-button {
    position: absolute;
    right: 50px;
    top: 30px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: none;
    background-color: #FFF;

    @media (max-width: 430px) {
      right: 20px;
      top: 15px;
      padding: 0;
    }
  }

}

.send-request-attach-survey-button {
  color: var(--accent-2, #0593F5);
  font-family: Gilroy;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  text-decoration-line: underline;
  border: none;
  background-color: #FFF;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;

  svg {
    align-items: center;
    margin-left: 6px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 130%;
  }

  @media (max-width: 430px) {
    font-size: 14px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-top: 10px;
  }
}

.send-request-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  @media (max-width: 430px) {
    font-size: 14px;
    margin-top: 0;
  }
}

.send-request-demo-send-button {
  margin: 0 auto;
}

.send-request-file-name {
  color: var(--txt-normal, #222);
  font-family: Gilroy;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  border: none;
  background-color: #FFF;
  margin-top: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 35ch;
}

.request-demo-overlay {
  &.scrollable {
    overflow-y: scroll;
    align-items: flex-start;
  }

  .send-request-modal {
    padding: 25px 40px 25px 40px;

    @media (max-width: 430px) {
      padding: 40px;
    }
  }
}

.certificate-modal-overlay,
.request-demo-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}

.certificate-modal {
  position: relative;
  max-width: 900px;
  width: 100%;
  overflow: hidden;

  @media (max-width: 430px) {
    zoom: 0.95;
  }
}

.certificate-modal-buttons {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.certificate-modal-image-container {
  display: flex;
  justify-content: center;
  align-items: center;

}


.certificate-modal-image {
  width: 600px;
  height: 900px;

  @media (max-width: 1024px) {
    width: 1250px;
    height: 1250px
   }
   
  @media (max-width: 800px) {
    width: 70%;
    height: 70vh;

   }

  @media (max-width: 430px) {
    width: 70%;
    height: 70vh;
  }
}
.certificate-modal-buttons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  padding: 10px;

  @media (max-width: 768px) {
    padding: 24px;
  }
}

.certificate-modal-prev-button,
.certificate-modal-next-button,
.certificate-modal-close-button {
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 7px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.40);
  backdrop-filter: blur(25px);
}

.certificate-modal-prev-button,
.certificate-modal-next-button {
  margin-right: 24px;

  @media (max-width: 1024px){
    background-color: grey;
    width: 50px;
    height: 50px;
    margin-right: 0px;
  }
  @media (max-width: 430px) {
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    background-color: white;
    width: 25px;
    height: 25px;
    padding: 2px;
  }
}

.certificate-modal-close-button {
  margin-left: auto;
  margin-right: 36px;

  @media (max-width: 1024px){
    background-color: white;
    margin-bottom: 10px;
    margin-right: 0px;
    width: 50px;
    height: 50px;
  }

  @media (max-width: 430px) {
    margin-bottom: -30px;
    margin-right: 20px;
    width: 30px;
    height: 30px;
  }
}

.document-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
}


.document-modal {
  overflow-y: auto;
  position: fixed; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  flex-flow: column;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  max-width: 746px;
  width: 900px;
  height: 100vh;
  scrollbar-width: thin;
  scrollbar-color: rgba(34, 34, 34, 0.60);
  padding: 20px;
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(34, 34, 34, 0.60);
    border-radius: 100px;
  }

  &::-webkit-scrollbar-track {
    background-color: #E9EAEC;
    border-radius: 100px;
  }

  @media (max-width: 430px) {
    width: 355px;
    height: 80vh;
  }
}



.document-modal-close {
  top: 0;
  right:0;
  position: absolute;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.40);
  backdrop-filter: blur(25px);

  @media (max-width: 430px) {
   margin-right: 0;
   margin-left: auto;
  }

}
.document-close{
  top: -60px;
  right:-100px;
  @media (max-width: 430px) {
    top: -50px;
    right:0px;
   }
}
.document-modal-content h2 {
  color: var(--txt-normal, #222);
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin: 0;
  padding: 0;

  @media (max-width: 430px) {
    color: var(--txt-normal, #222);
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }
}

.document-modal-content p {
  color: var(--txt-normal, #222);
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  padding-right: 20px;

  @media (max-width: 430px) {
    color: var(--txt-normal, #222);
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}

.news-document-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
  
}
.news-document-modal-overlay img{
  background: rgba(0, 0, 0, 0.5);
}

.news-document-modal {
  position: relative;
  max-width: 746px;
  width: 100%;
  // overflow: hidden;

  @media (max-width: 768px) {
    zoom: 0.95;
    height: 90vh;
  }

  @media (max-width: 430px) {
    width: 90%;
    height: 80vh;
  }
}
.news-document-modal-button{
  margin-top: 20%;
  @media (max-width: 430px) {
  }
}
.news-document-modal-content {
  background: #fff;
  height: 80vh;
  padding: 60px 40px;
overflow-y: auto;
overflow-x: hidden;
width: 90%;

&::-webkit-scrollbar {
  width: 10px;
}

&::-webkit-scrollbar-thumb {
  background-color: rgba(34, 34, 34, 0.60);
  border-radius: 100px;
}

&::-webkit-scrollbar-track {
  background-color: #E9EAEC;
  border-radius: 100px;
}

  a {
    color: #0593F5 !important
  }

  @media (max-width: 430px) {
    width: 100%;
    padding: 0px;
    // height: 100%;
    
&::-webkit-scrollbar {
  width: 5px;
}
  }



}

.news-document-modal-close-button {
  position: absolute;
  top: 26px;
  right: 25px;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.40);
  backdrop-filter: blur(25px);

  @media (max-width: 430px) {
    top: 0;
    right: 0;
  }
}

.news-document-modal-content img {
  width: 600px;

  @media (max-width: 430px) {
    width: 100%;
  }
}

.news-document-modal-content h2 {
  color: var(--txt-normal, #222);
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin: 0;
  padding: 0;

  @media (max-width: 430px) {
    color: var(--txt-normal, #222);
    font-family: Gilroy;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    width: 300px;
    margin-top: 20px;
  }
}

.news-document-modal-content p {
  color: var(--txt-normal, #222);
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  padding-right: 20px;
width: 100%;
height: 100%;
  @media (max-width: 430px) {
    color: var(--txt-normal, #222);
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}

.document-button {
  position: absolute;
  bottom: 40px;
  right: 40px;

  @media (max-width: 430px) {
    bottom: 20px;
    right: 20px;
  }
}
.document-modal-footer{
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;
}
.document-modal-footer-close-btn{
position: absolute;

@media (max-width: 430px) {
  position: fixed;
  max-height: 10vh;

}
}
.document-modal-text-content{
  max-height: 100vh;
 padding: 10px;

 @media (max-width: 430px) {
  max-height: 10vh;
  margin-top: -130%;
}
}
.scrollable-content {
  // min-height: 90vh;
  max-height: 1000vh;
  padding: 10px;
  margin-bottom: 10px;
  width: 660px;

  
  @media (max-width: 1024px) {
 font-size: 30px;
  }
  @media (max-width: 430px) {
    width: 95%;
    zoom: 0.95;
    margin-top: 10%;
    font-size: 15px;
  }

}

.request-demo-content {
  display: flex;
  width: 580px;
  padding: 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 2.5px solid var(--txt-border, #263238);
  background: var(--white, #FFF);
  padding: 30px;

  @media (max-width: 768px) {
    zoom: 0.95;
  }

  @media (max-width: 430px) {
    display: flex;
    width: 276px;
    padding: 20px 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

.request-demo-text {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex-direction: row;
  height: 42px;
  margin: 0;
  padding: 0;
  position: relative;

  @media (max-width: 430px) {
    height: 21px;
  }
}

.request-demo-block {
  position: relative;
  margin-bottom: 10px;
  width: 100%;

  @media (max-width: 430px) {
    margin: 0;
  }
}

.request-demo-block {
  display: flex;
  flex-direction: column;

  .custom-button {
    font-size: 24px;
    align-self: center;
  }

  @media (max-width: 768px) {
    .custom-button {
      width: 370px;
      height: 62px;
      padding: 22px 8px 22px 8px;
      gap: 10px;
      border: 2px 0px 0px 0px;
    }
  }

  @media (max-width: 430px) {
    .custom-button {
      width: 300px;
      height: 60px;
      padding: 22px 8px 22px 8px;
      gap: 10px;
      border: 3px 0px 0px 0px;
    }
  }
}

.request-demo-title {
  color: var(--txt-normal, #222);
  text-align: center;
  font-family: Gilroy-Medium;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 41.6px;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 31.2px;
  }

  @media (max-width: 430px) {
    margin-bottom: 30px;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    text-align: center;
  }
}

.request-demo-title-close-button {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5px;
  right: 0px;
  cursor: pointer;
  z-index: 1;

  @media (max-width: 430px) {
    top: -20px;
    right: -10px;
  }
}

.request-demo-block-text {
  color: var(--txt-normal, #222);
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;

  @media (max-width: 430px) {
    font-size: 16px;
    margin-top: 10px;
  }
}

.request-demo-checkbox-empty{
  width: 27px;
  height: 27px;
  border: 1px black solid;
}
.request-demo-checkbox {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  align-self: stretch;

  @media (max-width: 768px) {
    max-width: 350px;
  }

  @media (max-width: 430px) {
    gap: 10px;

    svg {
      width: 20px;
      margin-top: -15px;
    }
  }
}

.request-demo-agree-text {
  color: var(--grey, #949599) !important;
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  padding: 0;
  margin: 0;
  width: 450px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20.8px;
    text-align: left !important;
    line-height: 130% !important;
    width: 265px !important;
    margin: 0 !important;
    margin-top: 10px !important;
  }

  @media (max-width: 430px) {
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    text-align: left !important;
    line-height: 130% !important;
    width: 265px !important;
    margin: 10px 0 !important;
  }
}

.request-demo-highlight-text {
  color: var(--accent-2, #0593F5);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  text-decoration-line: underline;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20.8px;
  }

  @media (max-width: 430px) {
    font-size: 14px;
    text-decoration: none;
    color: var(--grey, #949599);
  }
}

.send-request-modal-overlay,
.feedback-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 11;
}

.feedback-form-content {
  display: flex;
  flex-direction: column;
  width: 580px;
  height: auto;
  padding: 40px;
  gap: 20px;
  border: 2.5px solid var(--txt-border, #263238);
  background: var(--white, #FFF);

  @media (max-width: 768px) {
    width: 500px;
    height: auto;
    padding: 40px;
    gap: 20px;
    border: 2.5px 0px 0px 0px;
  }

  @media (max-width: 430px) {
    width: 300px;
    height: auto;
    gap: 6px;
    padding: 20px 30px 20px 30px;
  }
}

.feedback-form-price {
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  flex-direction: column;

  @media (max-width: 430px) {
    font-size: 14px;
    line-height: 14px;
  }
}

.feedback-form-price-text-1 {
  font-family: Gilroy;
  font-size: 26px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }

  @media (max-width: 430px) {
    font-size: 14px;
    line-height: 18.2px;
  }
}

.feedback-form-price-text-2 {
  font-family: Gilroy;
  font-size: 26px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: -10px;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }

  @media (max-width: 430px) {
    font-size: 14px;
    line-height: 18.2px;
    margin-top: 10px;
  }
}

.feedback-form-price-text-3 {
  font-family: Gilroy;
  font-size: 26px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 5px;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }

  @media (max-width: 430px) {
    font-size: 14px;
    line-height: 18.2px;
  }
}

.feedback-form-subheader {
  align-self: stretch;
  color: var(--txt-normal, #222);
  font-family: Gilroy;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20.8px;
  }

  @media (max-width: 430px) {
    font-size: 16px;
  }
}

.feedback-form-block {
  position: relative;

  @media (max-width: 768px) {
    margin: 0 auto;
    max-width: 480px;
  }
}

.feedback-form-header {
  color: var(--txt-normal, #222);
  text-align: center;
  font-family: Gilroy-Medium;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  @media (max-width: 768px) {
    font-size: 24px;
    font-weight: 500;
    line-height: 31.2px;
  }

  @media (max-width: 430px) {
    font-size: 18px;
  }
}

.feedback-form-close-button {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 0px;
  cursor: pointer;
  border: none;
  background-color: #FFF;

  @media (max-width: 768px) {
    top: 0px;
    right: 20px;
  }

  @media (max-width: 430px) {
    top: 0px;
    right: -10px;
  }
}

.feedback-form-send-button {
  margin: 0 auto;
}

.price-form-title {
  @media (max-width: 430px) {}
}

.price-form-input {
  @media (max-width: 430px) {}
}

.price-form-content {
  height: auto;

  @media (max-width: 768px) {
    zoom: 0.95;
  }

  @media (max-width: 430px) {
    height: auto;
    padding: 25px 40px 40px 40px;
  }
}

.document-modal-footer{
  display: flex;
 justify-content: end;
}