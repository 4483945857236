.features-section {
width: 100%;
height: auto;
}
.custom-prev-arrow,
.custom-next-arrow{
  top:50%;

}
.custom-prev-arrow {
  left:1320%;

}
.features-section-container {
display: flex;
flex-direction: column;
margin: 0 auto;
width: 100%;
position: relative;
z-index: 1;
max-width: 1900px;
h2 {
  @media (max-width: 768px) {
    margin-left: 24px;
  }
}
}
.features-blocks {
display: inline-flex;
flex-direction: column;
align-items: flex-start;
gap: 20px;
z-index: 1;
svg {
width: 906px;
stroke-width: 1px;
stroke: var(--grey-light, #CBD1D5);
margin-top: -20px;
}
}
.features-card-container {
display: inline-flex;
flex-direction: row;
align-items: center;
gap: 18px;
justify-content: center;
width: 100%;
flex-wrap: wrap;
z-index: 1;
@media (max-width: 768px) {
  gap: 10px;
}
}
.features-button {
  display: flex;
  width: 280px;
  height: 10.5px;
  padding: 10px 22px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  color: var(--accent-2, #0593F5);
text-align: center;
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 130%; 
margin-top: 16px;
}
@keyframes moveAtom1 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(50px, 30px);
  }
  100% {
    transform: translate(100px, 60px);
  }
}
@keyframes moveAtom2 {
  0% {
    transform: translate(0, -170px);
  }
  50% {
    transform: translate(-60px, -70px);
  }
  100% {
    transform: translate(-100px, 00px);
  }
}
@keyframes moveAtom3 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(70px, -50px);
  }
  100% {
    transform: translate(140px, 0);
  }
}
.features-atom-1 {
  position: absolute;
  top: 120px;
  right: 20px;
  z-index: -1;
  pointer-events: none;
  animation: moveAtom1 10s infinite alternate;
  @media (max-width: 768px) {
    width: 100px;
    }
}
.features-atom-2 {
  position: absolute;
  bottom: -150px;
  left: 90px;
  z-index: -1;
  pointer-events: none;
  animation: moveAtom2 10s infinite alternate;
  @media (max-width: 768px) {
    width: 300px;
    }}
.features-atom-3 {
  position: absolute;
  bottom: 180px;
  right: 120px;
  z-index: -1;
  pointer-events: none;
  animation: moveAtom3 10s infinite alternate;
  @media (max-width: 768px) {
    width: 100px;
    }}
/* mobile */
.mobile-features-section-container {
display: flex;
flex-direction: column;
margin: 20px 20px;
width: 100%;
position: relative;
z-index: 1;
h2 {
  @media (max-width: 430px) {
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 20px;
  }
}
}
.mobile-features-blocks {
display: inline-flex;
flex-direction: column;
align-items: flex-start;
gap: 20px;
z-index: 1;
}
.mobile-features-block-1-line {
  width: 269px;
stroke-width: 1px;
stroke: var(--grey-light, #CBD1D5);
margin-top: -30px;
}

.mobile-features-block-2-line {
  width:322px;
stroke-width: 1px;
stroke: var(--grey-light, #CBD1D5);
margin-top: -30px;
}

.mobile-features-block-1 {
display: flex;
align-items: center;
justify-content: space-between;
width: 274px;
z-index: 1;
p {
  color: var(--txt-normal, #222);
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; 
  word-wrap: break-word;
  width: 240px;
}
svg {
  width: 24px;
  height: 24px;
  margin-top: 5px;
  cursor: pointer;
}
}
.mobile-features-block-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 327px;
  z-index:8;
  p {
    color: var(--txt-normal, #222);
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; 
    word-wrap: break-word;
    width: 293px;
  }
  svg {
    width: 24px;
    height: 24px;
    margin-top: 5px;
    cursor: pointer;
  }
  }
.mobile-features-card-container {
  margin-left: 10%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: auto;
flex-wrap: nowrap;
z-index: 1;
position: relative;
@media (max-width: 400px) {
  margin-left: 30px;
}
@media (max-width: 375px) {
  margin-left: 20px;
}
@media (max-width: 360px) {
  margin-left: 10px;
}
@media (max-width: 320px) {
  margin-left: 10px;
}
}
.mobile-features-section-container .slick-slider {
  width: 100% !important; 
}

.mobile-features-section-container .slick-prev,
.mobile-features-section-container .slick-next {
  font-size: 16px; 
}
.mobile-arrows-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}
.mobile-arrows-container svg {
  width: 26px;
  height: 26px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.40);
  backdrop-filter: blur(3.6500000953674316px);
}
.mobile-features-button {
  display: flex;
  width: 240px;
  height: 10.5px;
  padding: 10px 22px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  color: var(--accent-2, #0593F5);
text-align: center;
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 130%; 
margin-top: 16px;
}
@keyframes moveFeaturesAtom1 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(50px, -20px);
  }
  100% {
    transform: translate(100px, 0);
  }
}

.mobile-features-atom-1 {
  position: absolute;
  bottom: -150px;
  right: 0px;
  z-index: 1;
  pointer-events: none;
  animation: moveFeaturesAtom1 5s infinite alternate;
}