.case-card-container {
  position: relative;
    display: flex;
    width: 483px;
   max-height: 100%;
padding: 20px;
  margin-left: 55px;
flex-direction: column;
justify-content: center;
align-items: center;
flex-shrink: 0;
border: 3px solid var(--txt-border, #263238);
background: #FFF;

&:hover {
border: 6px dashed var(--accent-2, #0593F5);
background: rgba(5, 147, 245, 0.50);
backdrop-filter: blur(5px);
padding: 16px;

}

@media (max-width: 1024px) {
  width: 750px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  width: 420px;
  max-height: 100%;
  padding: 20px ;
  border: 3px 0px 0px 0px;
  background: #FFFFFF;
  border: 3px solid #263238  
} 
@media (max-width: 430px) {
    width: 295px;
    height: auto;
    min-height: 600px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
} 

}
.case-card {
    
    display: flex;
    width: 500px;
    width: 100%;
    height: 100%;
    padding: 0px 10px 10px 10px;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    gap: 20px;
    flex-shrink: 0;
    border: 1px solid var(--txt-border, #263238);
background: #FFF;

@media (max-width: 1024px) {
  width: 750px;
  height: auto;
  min-height: 600px;
  gap: 5px;
}
@media (max-width: 768px) {
  width: 420px;
  height: 554px;
  padding: 0px ;
  border: 1px 0px 0px 0px;
  border: 1px solid #263238  
}
@media (max-width: 430px) {
    width: 295px;
    height: auto;
    min-height: 600px;
    gap: 5px;
}

  }
  .case-card-header {
    display: flex;
flex-direction: column;
align-items: flex-start;
width: 100%;
@media (max-width: 430px) {
  max-width: 295px;
}
  }
  .case-card-logo {
    width: 261px;
    height: 98px;
    margin: 10px;
    background: url(/logo.svg), lightgray -14.646px -4.898px / 109.129% 120.408% no-repeat;
    @media (max-width: 768px) {
width: 160px;
height: 60px;
margin: 10px;
    }
  }
  .case-card-titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    width: 100%;
    height: 100%;
    @media (max-width: 430px) {
      max-width: 295px;
    }
  
  }
  .case-card-title {
    text-align:center;
    color: var(--txt-normal, #222);
    font-family: Gilroy-Medium;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
margin: 0;
padding: 0;
    line-height: 130%; 
    @media (max-width: 1024px) {
      font-size: 45px;
      line-height: 50.2px;
        }

    @media (max-width: 768px) {
      font-size: 24px;
      font-weight: 400;
      line-height: 31.2px;
    }
    @media (max-width: 430px) {
      font-size: 24px;
      line-height: 31.2px;
    }
  }
  .case-card-subtitle {
    width: 100%;
    color: var(--txt-normal, #222);
text-align: center;
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 130%; 
height: 100%;
margin: 0;
padding: 0;

@media (max-width: 1024px) {
  font-size: 35px;
  line-height: 50.2px;

    }
@media (max-width: 768px) {
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  max-width: 415px;
}
@media (max-width: 430px) {
  font-size: 16px;
  line-height: 18.2px;
  max-width: 275px;
}
  }
  .case-card-footer{
    width: 10%;
    
    display: flex;
    justify-content: space-around;
    
  }
  .case-card-content {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    color: var(--txt-normal, #222);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 130%; 
margin: 0;
padding: 0;
margin-bottom: 20px;
padding: 10px;
@media (max-width: 768px) {
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  max-width: 415px;
}
@media (max-width: 430px) {
font-size: 13px;
font-weight: 400;
width: 275px;
height: auto;
}
  }
  .case-card-content ul {
    margin: 0;
    padding-left: 40px;
    padding-bottom: 10px;
    height: 100%;
    width: 90%;
    li {
      margin-top: 20px;

      @media (max-width: 1024px) {
        font-size: 30px;
        line-height: 50.2px;
        width: 100%;
          }
   
          @media (max-width: 430px) {
            font-size: 15px;
            line-height: 20.2px;
          }
    }
  }
  .case-card-content  p{
    @media (max-width: 1024px) {
      font-size: 30px;
      line-height: 130%;
      font-weight: 400;
      width: 100%;
      height: auto;
      
      }
      @media (max-width: 430px) {
        font-size: 20px;
        line-height: 22.2px;
      }
  }
  .button-style-4:hover{
    color: white;
  }
  .case-card:hover .case-card-button.button-style-4 {
      position: absolute;
      display: flex;
      bottom: 15px;
      right: 10px;
      color: var(--accent-2, #0593F5);
      text-align: center;
      font-family: Gilroy;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      
  }
  .case-card-button.button-style-4 {
    display: none;

  }
  .case-card-btn-span{
    width: 100%;
    margin: 0;
  }
  .case-card-button:hover .case-card-btn-span{
    color: white;
  }