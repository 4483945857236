.plan-scheme-section {
  width: 100%;
  max-width: 1900px;
  margin-top: 20px;
}
.plan-scheme-section-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  z-index: 1;
}
.plan-scheme-image {
  pointer-events: none;
}
.plan-scheme-group-image {
  z-index: -1;
  position: absolute;
  left: -320px;
  bottom: -30px;
  pointer-events: none;
}
.plan-scheme-container {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  margin-top: 100px;
}
.plan-button {
  margin-bottom: -80px;
  margin-left: auto;
  z-index: 10;
  margin-right: 24px;

  svg {
    z-index: 11;
  }
}
/* tablet */
.tablet-plan-scheme-section {
  width: 100%;
  margin: 0;
  h2 {
    margin-left: 24px;
  }
}
.tablet-plan-scheme-section-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 1;
  height: 500px;
}
.tablet-plan-scheme-image {
  z-index: 1;
  position: absolute;
  left: 370px;
  top: 75px;
  pointer-events: none;
}
.tablet-plan-scheme-group-image {
  z-index: 1;
  position: relative;
  margin: 0 auto;
  pointer-events: none;
}
.tablet-plan-scheme-container {
  display: flex;
  align-items: center;
  position: relative;
}
/* mobile */
.mobile-plan-scheme-section {
  width: 100%;
  h2 {
    margin-left: 20px;
  }
}

.mobile-plan-scheme-section-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 1;
  height: 300px;
}

.mobile-plan-scheme-image {
  z-index: 1;
  position: absolute;
  left: 0px;
  top: 5px;
  pointer-events: none;
}

.mobile-plan-scheme-group-image {
  z-index: 1;
  position: relative;
  pointer-events: none;
}

.mobile-plan-scheme-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.certificates-block {
  width: 100%;
  height: 100%;
  margin-top: 70px;
  @media (max-width: 768px) {
    margin-top: 0;
  }
}
.custom-prev-arrow{
right:-52px;
    @media (max-width: 450px) {
      top:50%
    }
}
.custom-next-arrow{
left:12px;
    @media (max-width: 450px) {
    top:50%
    }
}
.certificates-block-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
  position: relative;
  max-width: 1900px;
  img{
    width: 600px;
    height: 900;
    margin: 70px auto;
    @media (max-width: 900px) {
    
    }
    @media (max-width: 450px) {
      // margin-left: 25%;
      width: 300px;
      height: 300;
    }
  }
  
  h2 {
    color: rgb(13, 55, 84);
    @media (max-width: 768px) {
      margin-left: 24px;
    }
  }
}
.certificates-block-container h2 {
  text-align: start;

}
