.cases-section {
    width: 100%;
    margin-top: 70px;
    @media (max-width: 768px) {
      margin-top: 0;;
    }
    }
.cases-section-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
width: 100%;
position: relative;

max-width: 1900px;
h2 {
  @media (max-width: 768px) {
  margin-left: 24px;
  }
}
}
.--next{
  left:-72px;
  @media (max-width: 908px) {
    left: 2px;
  }
}
.case-cards {
  display: flex;
  width: 100%;
justify-content: space-around;

}
.case-cards-slider{
  width: 100%;
}
  .cases-section-button {
    margin-top: 40.5px;
    margin-right: 24px;
    @media (max-width: 768px) {
      margin-top: 24px;
      padding: 12px 16px;
    }
  }
  .case-section-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  @keyframes moveCaseAtom1 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(100px, 50px);
    }
    100% {
      transform: translate(200px, 0);
    }
  }
  @keyframes moveCaseAtom2 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(-50px, 100px);
    }
    100% {
      transform: translate(0, 200px);
    }
  }
  @keyframes moveCaseAtom3 {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(50px, -50px);
    }
    100% {
      transform: translate(100px, 0);
    }
  }
  .case-atom-1 {
    position: absolute;
    bottom: -100px;
    left: -200px;
    pointer-events: none;
    animation: moveCaseAtom1 5s infinite alternate;
    @media (max-width: 768px) {
      width: 200px;
    }
  }
  .case-atom-2 {
    position: absolute;
    top: 0px;
    left: 370px;
    pointer-events: none;
    animation: moveCaseAtom2 5s infinite alternate;
    @media (max-width: 768px) {
      width: 200px;
    }
  }
  .case-atom-3 {
    position: absolute;
    top: -85px;
    right: -10px;
    pointer-events: none;
    animation: moveCaseAtom3 5s infinite alternate;
    @media (max-width: 768px) {
      width: 200px;
    }
  }
  /* tablet */
  .tablet-cases-section-container {
    display: flex;
    flex-direction: column;
    margin: 0;
width: 100%;
position: relative;
h2 {
  margin-left: 24px;
}
}
.tablet-case-cards {
    display: flex;
align-items: center;
gap: 16px;
width: 100%;
z-index: 1;
}
.tablet-cases-section {
  width: 100%;
}
  .tablet-cases-section-button {
    display: flex;
    width: 270px;
    height: 14px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    border: 3px solid var(--accent-2, #0593F5);
background: #FFF;
color: var(--accent-2, #0593F5);
margin-top: 24px;
margin-left: auto;
margin-right: 24px;
  }
  .tablet-case-section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
.tablet-cases-section-container .slick-slider {
  width: 185% !important; 
}
.tablet-cases-section-container .slick-prev,
.tablet-cases-section-container .slick-next {
  font-size: 16px; 
}
.tablet-cases-arrows-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  width: 70%;
  transform: translateY(-50%);
}
.tablet-cases-arrows-container svg {
  width: 26px;
  height: 26px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.40);
  backdrop-filter: blur(3.6500000953674316px);
}
@keyframes moveAtom1 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(100px, 50px);
  }
  100% {
    transform: translate(200px, 0);
  }
}
@keyframes moveAtom2 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-50px, 100px);
  }
  100% {
    transform: translate(0, 200px);
  }
}
.tablet-case-atom-1 {
  position: absolute;
  bottom: -120px;
  left: -30px;
  z-index: -1;
  pointer-events: none;
  animation: moveAtom1 5s infinite alternate;
}
.tablet-case-atom-2 {
  position: absolute;
  top: -40px;
  right: 45px;
  z-index: -1;
  pointer-events: none;
  animation: moveAtom2 5s infinite alternate;
}
  /* mobile */
  .mobile-cases-section {
    width: 100%;
  }
  .mobile-cases-section-container {
    display: flex;
    flex-direction: column;
width: 100%;
position: relative;
margin-top: 50px;
h2 {
  margin-left: 20px;
}
}
.mobile-case-cards {
    display: flex;
align-items: center;
gap: 16px;
width: 345px;
margin: 0 auto;
z-index: 1;
}
  .mobile-cases-section-button {
    display: flex;
    width: 236px;
    height: 14px;
    padding: 15px 19px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex-shrink: 0;
    margin-top: 20px;
    margin-right: 20px;
  }
  .mobile-case-section-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .mobile-cases-section-container .slick-slider {
    width: 100% !important; 
  }
  .mobile-cases-section-container .slick-prev,
  .mobile-cases-section-container .slick-next {
    font-size: 16px; 
  }
  .mobile-cases-arrows-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 45%;
    width: 100%;
    transform: translateY(-50%);
    margin-left: -20px;
  }
  .mobile-cases-arrows-container svg {
    width: 26px;
    height: 26px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.40);
    backdrop-filter: blur(3.6500000953674316px);
    }