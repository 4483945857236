.feedback-modal{
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}

.feedback-modal-container{
    width: 906px;
    height: 465px;
    position: relative;
    z-index: 3;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    @media (max-width: 768px) {
      width: 700px;
      height: 451px;
    }
  
    @media (max-width: 430px) {
      display: flex;
      width: 305px;
      height: 317px;
      padding: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 138px;

    }
  
    @media (max-width: 43px) {
      width: 90%;
      height: 451px;
    }
    // @media (max-width: 320px) {
    //   width: 265px;
    //   height: 317px;
    // }

}

.feedback-modal-container::before {
    content: "";
    width: 100%;
    height: 100%;
    border: 10px dashed var(--white, #FFF);
    background: rgba(5, 147, 245, 0.50);
    backdrop-filter: blur(5px);
    position: absolute;
    z-index: 2;
  
    @media (max-width: 430px) {
      width: 95%;
      height: 465px;
    }
  }

  .feedback-modal-content{
    border: 1px solid var(--txt-border, #263238);
    background-color: white;
    width: 866px;
    height: 425px;
    position: absolute;
    z-index: 8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    @media (max-width: 768px) {
      width: 700px;
      height: 451px;
    }
    @media (max-width: 430px) {
      width: 90%;
      height: 441px;
    }
  }

.feedback-modal-close-header-btn{
  position: absolute;
  top: -20px;
  right:  10px;
  cursor: pointer;
  color: gray;
}
  .feedback-modal-content h2 {
    width: 690px;
    color: #0D3754;
    text-align: center;
    font-family: Gilroy-Medium;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin: 0;
    padding: 0;
  
    @media (max-width: 768px) {
      font-size: 24px;
      font-weight: 400;
      line-height: 31.2px;
    }
  
    @media (max-width: 430px) {
      color: #0D3754;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      margin: 0;
    }
  }

  .feedback-modal-form-container{
    margin-top: 20px;
  }
  .feedback-modal-form-input{
    position: relative;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .feedback-modal-form-input-label{
    position: absolute;
    top:-30px;

    @media (max-width: 430px) {
      font-size: 17px;
      top:-20px;
    }
  }