.our-partners-section {
  width: 100%;
  h2 {
    @media (max-width: 768px) {
      margin-left: 24px;
      }
  }
}
.parnters-section {
    width: 100%;
    flex-shrink: 0;
    padding-bottom: 80px;
    border-top: 10px dashed var(--white, #FFF);
    border-bottom: 10px dashed var(--white, #FFF);
background: #B4DFFC;
margin: 0 auto;
}
.partners-container {
justify-content: center;
margin: 0 auto;
max-width: 1900px;
@media (max-width: 1024px) {
  margin-left: 22px;
  }
}
.slick-prev:before, .slick-next:before {
  display: none;
}
.partners-container-text {
    width: 65%;
    color: #000;
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 130%;
}
.partners-container-slider {
    width: 100%;
}
.parnters-slide, .parnters-slide .slick-slider {
    width: 95% !important;
    height: 260px;
    display: inline-flex;
flex-direction: column;
justify-content: flex-end;
align-items: center;
padding-bottom: 10px;
margin-left: 1px;
border: 2.5px solid var(--txt-border, #263238);
background: #FFF;
@media (max-width: 768px) {
max-height: 333px;
}
@media (max-width: 600px) {
  width: 99% !important;
  }
@media (max-width: 425px) {
  width: 95% !important;
  }
@media (max-width: 430px) {
width: 100% !important;
height: auto;
max-width: 280px !important;
margin-top: 20px;
}
  }
  .parnters-slide img {
background: #FFF;
margin: 0;
padding: 0;
margin-top: 10px;
object-fit: contain;
overflow: hidden;
@media (max-width: 430px) {
  width: 157px;
  height: 47px;
}
  }
  .parnters-slide h2 {
    color: #000;
font-family: Gilroy-Medium;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: 130%;
margin: 0;
padding: 0;
margin-top: 20px;
@media (max-width: 430px) {
  color: #000;
  font-family: Gilroy-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
  }
  .parnters-slide p {
    color: #000;
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 130%;
margin: 0;
padding: 0;
margin-top: 20px;
@media (max-width: 430px) {
  color: #000;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
  }
  .parnters-slide a {
    color: var(--accent-2, #0593F5);
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 130%;
text-decoration-line: underline;
margin: 0;
padding: 0;
margin-top: 20px;
@media (max-width: 430px) {
  color: var(--accent-2, #0593F5);
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; 
}
  }
  /* tablet */
  .tablet-parnters-section {
    width: 100%;
    border-top: 10px dashed var(--white, #FFF);
    border-bottom: 10px dashed var(--white, #FFF);
background: #B4DFFC;
}
.tablet-partners-container {
width: 100%;
justify-content: center;
margin: 0 auto;
}
.tablet-partners-container-text {
    width:458px;
    color: #000;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; 
    margin-left: 24px;
}
.tablet-partners-container-slider {
    width: 650px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 40px;
    .parnters-slide {
      margin: 0 auto;
    }
}
  /* mobile */
  .mobile-parnters-section {
    width: 100%;
    height: 400px;
    border-top: 10px dashed var(--white, #FFF);
    border-bottom: 10px dashed var(--white, #FFF);
background: #B4DFFC;
@media (max-width: 660px) {
height: auto;
}
h2 {
  margin: 0 auto;
  margin-top: 20px;
}
.mobile-slick-prev {
  margin-left: 0px;
}
.mobile-slick-next {
  margin-left: 25px;
}
}
.mobile-parnters-section {
  overflow-x: auto;
}
.mobile-partners-container {
justify-content: center;
width: auto;
margin-left: 20px;
}
.mobile-partners-container-text {
    width: 290px;
    color: #000;
    font-family: Gilroy;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; 
}
.mobile-partners-container-slider {
    width: 100%;
  margin-bottom: 20px;
  margin-top: 10px;
    .parnters-slide {
      margin-left: 16%;
      @media (max-width: 375px) {
        margin-left: 12%;
    }
    @media (max-width: 320px) {
      margin-left: 10%;
  }
}
}
.mobile-slick-prev, .mobile-slick-next {
  background: rgba(255, 255, 255, 0.40);
backdrop-filter: blur(3.6500000953674316px);
}